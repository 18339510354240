import { type MouseEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import {
    ButtonColor,
    ButtonLoader,
    ButtonSize,
    ButtonVariant,
    CalendarIconSrc,
    Image,
    LoadingSteps,
    PureLink,
    TrashIconSrc,
    useLoadingSteps,
} from '@jsmdg/yoshi';
import { type VoucherResource } from '../../../shared/types';
import styles from './VoucherItem.module.scss';

const messages = defineMessages({
    expiryDate: {
        defaultMessage: 'Gültig bis',
    },
    voucherCodeLabel: {
        defaultMessage: 'Gutscheinwert',
    },
    voucherRemoveError: {
        defaultMessage:
            'Das Entfernen des Gutscheins ist fehlgeschlagen. Bitte versuche es erneut.',
    },
});

type VoucherItemType = VoucherResource & {
    readonly handleRemoveVoucherCode: (voucherCode: string) => Promise<void>;
    readonly setErrorMessage: (content: string) => void;
};

const VoucherItem = ({
    balance,
    code,
    expiryDate,
    externalReference,
    handleRemoveVoucherCode,
    id,
    productImage,
    productTitle,
    setErrorMessage,
}: VoucherItemType): JSX.Element => {
    const intl = useIntl();
    const { setLoadingFinished, setLoadingStarted, step } = useLoadingSteps();
    const url = externalReference?.externalUrl;

    const onVoucherClick = (event: MouseEvent): void => {
        event.preventDefault();
        trackAnalyticsEvent({
            eventData: {
                eventName: GA4EventName.ClickProductPicture,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                product_title: productTitle,
            },
        });

        if (url) window.location.href = url;
    };

    const removeVoucher = async (event: MouseEvent): Promise<void> => {
        event.preventDefault();
        trackAnalyticsEvent({
            eventData: {
                eventName: GA4EventName.DeleteVoucher,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                product_title: productTitle,
            },
        });

        try {
            setLoadingStarted();
            await handleRemoveVoucherCode(id);
        } catch {
            setErrorMessage(intl.formatMessage(messages.voucherRemoveError));
            setLoadingFinished();
        }
    };

    return (
        <div className={styles.voucher}>
            <div className="d-flex">
                <PureLink href={url} onClick={onVoucherClick} data-testid="voucher-link">
                    <Image
                        src={productImage?.url ?? ''}
                        alt={productImage?.alt ?? ''}
                        className={styles.image}
                        lazyLoad
                    />
                </PureLink>

                <div className="d-flex flex-grow-1 flex-column ml-1-5x">
                    <div className="d-flex justify-content-between">
                        <PureLink href={url} onClick={onVoucherClick}>
                            <h3 className={classNames(styles.productTitle, 'fw-bold text-wrap')}>
                                {productTitle}
                            </h3>
                        </PureLink>
                        <ButtonLoader
                            dataTestId="remove-voucher-button"
                            size={ButtonSize.Small}
                            className={classNames(styles.trashButton, 'pr-0')}
                            variant={ButtonVariant.Ghost}
                            color={ButtonColor.Brand}
                            disabled={
                                step >= LoadingSteps.Started && step <= LoadingSteps.LongLoading
                            }
                            isLoading={
                                step >= LoadingSteps.Loading && step <= LoadingSteps.LongLoading
                            }
                            onClick={removeVoucher}
                        >
                            <Image
                                src={TrashIconSrc}
                                alt="remove voucher"
                                lazyLoad
                                className={styles.trashIcon}
                            />
                        </ButtonLoader>
                    </div>

                    {expiryDate && (
                        <div
                            className={classNames(
                                styles.expiryDateLayout,
                                'd-flex align-items-center text-wrap',
                            )}
                        >
                            <Image
                                src={CalendarIconSrc}
                                alt="calendar icon"
                                lazyLoad
                                className="align-middle mr-0-5x"
                            />
                            <span className={styles.expiryDate}>
                                {intl.formatMessage(messages.expiryDate)}&nbsp;
                                <span className="fw-bold">{expiryDate}</span>
                            </span>
                        </div>
                    )}
                </div>
            </div>

            <div className={classNames('d-flex mt-1-5x', styles.voucherCodeLabel)}>
                {intl.formatMessage(messages.voucherCodeLabel)}
            </div>
            <div className="d-flex justify-content-between">
                <div className={styles.voucherCode}>{code}</div>
                <div className={styles.voucherBalance}>
                    {intl.formatNumber(Number(balance.amount), {
                        style: 'currency',
                        currency: balance.currencyCode,
                    })}
                </div>
            </div>
        </div>
    );
};

export { VoucherItem };
