import {
    type ChangeEvent,
    type Dispatch,
    type FormEvent,
    type KeyboardEvent,
    type SetStateAction,
    useRef,
    useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { getRedemptionStorage } from '@jsmdg/browser-storage';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FeatureCategory, trackAnalyticsEvent } from '@jsmdg/tracking';
import { createCustomUIEvent, CustomUIEventEnum } from '@jsmdg/ui-event';
import { type LoadingSteps, useLoadingSteps } from '@jsmdg/yoshi';
import { type RedemptionInformation } from '../../shared/types';
import { getVoucherService } from '../helper/getVoucherService';
import { parseVoucherError } from '../helper/parseVoucherError';
import { type HeaderContext } from '../types';
import { useRedemptionContext } from './useRedemptionContext';

const messages = defineMessages({
    voucherMistypedError: {
        defaultMessage:
            'Ungültige Eingabe. Der Gutschein-Code sollte 4 bis 19 Zeichen lang sein. Leerzeichen sind optional.',
    },
    voucherInvalidCurrencyError: {
        defaultMessage:
            'Der Gutscheincode ist nur über unsere Hotline einlösbar.{br}Bitte kontaktiere unsere Hotline {br}{br}{hotlineNumber}{br}{serviceHours}',
    },
    voucherTenantError: {
        defaultMessage:
            'Deinen eingegebenen Wertgutscheincode kannst du leider in diesem Shop nicht einlösen.{br}Löse ihn bitte in dem Shop ein, in dem du ihn gekauft hast.',
    },
    voucherExpiredError: {
        defaultMessage: 'Dieser Gutschein ist bereits abgelaufen',
    },
    voucherTerminatedError: {
        defaultMessage: 'Dieser Gutschein wurde storniert oder umgetauscht',
    },
    voucherInactiveError: {
        defaultMessage: 'Gutschein wurde noch nicht bezahlt',
    },
    voucherRedeemedError: {
        defaultMessage: 'Dieser Gutschein wurde bereits eingelöst',
    },
    voucherNotFoundError: {
        defaultMessage:
            'Wir konnten den Gutschein leider nicht in unserem System finden. Hast du dich vielleicht vertippt?',
    },
    voucherNotPaidError: {
        defaultMessage:
            'Die Zahlung für diesen Gutschein ist bei uns leider noch nicht eingegangen. Bei Zahlung per Rechnung kann es bis zu zwei Tage dauern, bis deine Überweisung bei uns ankommt. Versuch es bitte später noch einmal.',
    },
    voucherBalanceZeroError: {
        defaultMessage: 'Dieser Gutschein hat kein Guthaben mehr.',
    },
    voucherBlockedError: {
        defaultMessage:
            'Der eingegebene Gutscheincode ist gesperrt. Für mehr Informationen wende dich bitte an unsere Hotline.{br}{br}{hotlineNumber}{br}{serviceHours}',
    },
    voucherInvalidError: {
        defaultMessage:
            'Dieser Gutschein ist bereits abgelaufen oder eingelöst worden. Hast du dich vertippt?',
    },
    genericServerError: {
        defaultMessage: 'Ups, da ist etwas schiefgelaufen. Versuche es später erneut.',
    },
    longerThanUsual: {
        defaultMessage: 'Fast da! Bitte warte noch ein paar Sekunden, oder versuche es erneut.',
    },
    offline: {
        defaultMessage: 'Das dauert länger als üblich. Bitte überprüfe deine Internetverbindung.',
    },
});

const voucherRemoveMessages = defineMessages({
    vouchersEmpty: {
        defaultMessage: 'Du hast all deine Gutscheine entfernt.',
    },
});

type ErrorTypesEnum = keyof typeof messages;
const VOUCHER_LENGTH_MIN = 4;
const VOUCHER_LENGTH_MAX = 16;

const ERROR_CODE_LOOKUP: Record<number, string> = {
    606: 'voucherMistypedError',
    900: 'voucherInvalidCurrencyError',
    902: 'voucherTenantError',
    903: 'voucherExpiredError',
    904: 'voucherNotFoundError',
    905: 'voucherBalanceZeroError',
    906: 'voucherNotPaidError',
    907: 'voucherBlockedError',
    910: 'voucherInvalidError',
    911: 'voucherRedeemedError',
    912: 'voucherTerminatedError',
    913: 'voucherInactiveError',
    500: 'genericServerError',
};

enum VoucherRemoveType {
    Success = 'success',
    Error = 'error',
}

enum KeyboardEventEnum {
    Backspace = 'Backspace',
    Delete = 'Delete',
    Whitespace = 'Whitespace',
    Enter = 'Enter',
}

type UseVoucherFormsReturn = {
    inputValue: string;
    step: LoadingSteps;
    error: string | null;
    handleSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
    redemptionInformation: RedemptionInformation | null;
    handleRemoveVoucherCode: (voucherCode: string) => Promise<void>;
    voucherRemoveMessage: {
        content: string | null;
        type: VoucherRemoveType | null;
    };
    setErrorMessage: (content: string) => void;
    setRedemptionInformation: Dispatch<SetStateAction<RedemptionInformation>>;
};

const useVoucherForm = (): UseVoucherFormsReturn => {
    const intl = useIntl();
    const { addVoucherCode, removeVoucherCode } = useFragmentContext<HeaderContext>();
    const { redemptionInformation, setRedemptionInformation } = useRedemptionContext();
    const [skipFormat, setSkipFormat] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const { step, setLoadingStarted, setLoadingFinished } = useLoadingSteps();
    const resultInput = useRef<string>();
    const resultPromise = useRef<Promise<RedemptionInformation>>();
    const voucherService = getVoucherService();
    const [voucherRemoveMessage, setVoucherRemoveMessage] = useState<{
        content: string | null;
        type: VoucherRemoveType | null;
    }>({
        content: null,
        type: null,
    });
    const redemptionStorage = getRedemptionStorage();

    const checkAndReturnTrimmedVoucher = (showErrors: boolean): string | false => {
        const trimmedVoucherCode = inputValue.replaceAll(/\s+?/g, '').toLocaleUpperCase();
        const len = trimmedVoucherCode.length;
        const isValidLength = len >= VOUCHER_LENGTH_MIN && len <= VOUCHER_LENGTH_MAX;

        if (!isValidLength) {
            if (showErrors) {
                setError(intl.formatMessage(messages.voucherMistypedError));
                // disable button until correct limit is reached
                setLoadingStarted();
            }

            return false;
        }

        return trimmedVoucherCode;
    };

    const fetchVoucher = (showErrors: boolean): boolean => {
        const trimmedVoucherCode = checkAndReturnTrimmedVoucher(showErrors);
        if (!trimmedVoucherCode) {
            resultInput.current = undefined;
            resultPromise.current = undefined;
            return false;
        }

        if (resultInput.current === trimmedVoucherCode) return true;

        resultInput.current = trimmedVoucherCode;
        resultPromise.current = addVoucherCode(trimmedVoucherCode, voucherService);
        return true;
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setSkipFormat(false);

        if (!fetchVoucher(true)) return;
        setLoadingStarted();

        try {
            const redemptionInfo = (await resultPromise.current) as RedemptionInformation;
            setRedemptionInformation(prev => ({ ...prev, ...redemptionInfo }));

            document.dispatchEvent(
                createCustomUIEvent(CustomUIEventEnum.AddVoucher, {
                    detail: {
                        amount: Number(redemptionInfo.vouchersTotal.amount),
                        currencyCode: redemptionInfo.vouchersTotal.currencyCode,
                    },
                }),
            );
            trackAnalyticsEvent({
                eventData: {
                    eventName: GA4EventName.AddVoucher,
                    feature_category: GA4FeatureCategory.HeaderNavigation,
                    voucher_adding_method: 'add voucher code manual type in',
                    voucher_value: inputValue,
                },
            });
            setInputValue('');
            setError(null);
            setVoucherRemoveMessage({ content: null, type: null });
        } catch (voucherFormError) {
            const parsedError = parseVoucherError(voucherFormError);
            const errorCode = parsedError?.status ?? 500;
            const errorType = ERROR_CODE_LOOKUP[errorCode];

            setError(intl.formatMessage(messages[errorType as ErrorTypesEnum]));
        }

        setLoadingFinished();
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        const target = e.target as HTMLInputElement;
        let { value } = target;

        if (!skipFormat) {
            value = value
                .replaceAll(/\s/g, '')
                .replace(/(.{4})(.{4})?(.{4})?(.{4})?/, '$1 $2 $3 $4');
        }

        // remove/prevent duplicate whitespaces
        value = value.replaceAll(/\s{2,}/g, ' ');

        setInputValue(value);

        if (
            error &&
            value.length >= VOUCHER_LENGTH_MIN &&
            value.length <= VOUCHER_LENGTH_MAX + 3 // 3 whitespaces
        ) {
            // UX: remove error when we hit VOUCHER_LENGTH_MIN again
            setLoadingFinished();
            setError(null);
        }

        // always activate auto formatting again after one character
        if (skipFormat) {
            setSkipFormat(false);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === KeyboardEventEnum.Backspace || e.key === KeyboardEventEnum.Delete) {
            setSkipFormat(true); // user pressed backspace: opt out of any formatting so users don't get confused
        } else if (skipFormat && e.key === KeyboardEventEnum.Whitespace) {
            setSkipFormat(false); // activate auto formatting again
        } else if (e.key === KeyboardEventEnum.Enter) {
            fetchVoucher(false);
        }
    };

    const setSuccessMessage = (content: string | null): void => {
        setVoucherRemoveMessage({ content, type: VoucherRemoveType.Success });
    };

    const setErrorMessage = (content: string): void => {
        setVoucherRemoveMessage({ content, type: VoucherRemoveType.Error });
    };

    const handleRemoveVoucherCode = async (voucherCode: string): Promise<void> => {
        if (voucherRemoveMessage) {
            setSuccessMessage(null);
        }

        const redemptionInfo = await removeVoucherCode(voucherCode);

        if (redemptionInfo.vouchersCount === 0) {
            setSuccessMessage(intl.formatMessage(voucherRemoveMessages.vouchersEmpty));
            redemptionStorage.clear();
        }

        setRedemptionInformation(prev => ({ ...prev, ...redemptionInfo }));

        document.dispatchEvent(
            createCustomUIEvent(CustomUIEventEnum.RemoveVoucher, {
                detail: {
                    amount: Number(redemptionInfo.vouchersTotal.amount),
                    currencyCode: redemptionInfo.vouchersTotal.currencyCode,
                },
            }),
        );
    };

    return {
        inputValue,
        step,
        error,
        handleSubmit,
        handleInputChange,
        handleKeyDown,
        redemptionInformation,
        handleRemoveVoucherCode,
        voucherRemoveMessage,
        setErrorMessage,
        setRedemptionInformation,
    };
};

export { useVoucherForm, VoucherRemoveType };
