import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Breakpoint, Notification, NotificationVariant, useBreakpoint } from '@jsmdg/yoshi';
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll';
import { useVoucherForm, VoucherRemoveType } from '../../hooks/useVoucherForm';
import { type HeaderContext } from '../../types';
import { VoucherForm } from './VoucherForm';
import { VoucherItem } from './VoucherItem';
import { VoucherModalHeader } from './VoucherModalHeader';
import styles from './VoucherModal.module.scss';

const messages = defineMessages({
    headline: {
        defaultMessage: 'Deine Gutscheine',
    },
});

type VoucherModalProps = {
    readonly onRequestClose: () => void;
    readonly mouseEnter: () => void;
};

const VoucherModal = ({ mouseEnter, onRequestClose }: VoucherModalProps): JSX.Element => {
    const intl = useIntl();
    const { customerInfo } = useFragmentContext<HeaderContext>();
    const isMobile = useBreakpoint(Breakpoint.XS);
    const {
        error,
        handleInputChange,
        handleKeyDown,
        handleRemoveVoucherCode,
        handleSubmit,
        inputValue,
        redemptionInformation,
        setErrorMessage,
        setRedemptionInformation,
        step,
        voucherRemoveMessage,
    } = useVoucherForm();

    useDisableBodyScroll(!isMobile);

    const handleMouseLeave = (): void => {
        if (voucherRemoveMessage.type === VoucherRemoveType.Success) {
            setRedemptionInformation(prev => ({ ...prev, isEnabled: false }));
        }

        onRequestClose();
    };

    return (
        <div
            className={classNames('position-absolute end-0 overflow-hidden', styles.wrapper, {
                'position-fixed top-0 start-0 w-100': !isMobile,
            })}
            onMouseEnter={mouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="mt-3x">
                <div className="px-3x">
                    <VoucherModalHeader
                        className="d-sm-none"
                        customerInfo={customerInfo}
                        onRequestClose={handleMouseLeave}
                    />

                    <h2 className="mb-1x">{intl.formatMessage(messages.headline)}</h2>
                </div>
                <ul className={classNames(styles.voucherslist, 'px-3x text-wrap')}>
                    {voucherRemoveMessage?.content && (
                        <li key={voucherRemoveMessage.content} className={styles.voucherMessage}>
                            <Notification
                                message={voucherRemoveMessage.content}
                                variant={
                                    voucherRemoveMessage.type === VoucherRemoveType.Error
                                        ? NotificationVariant.Error
                                        : NotificationVariant.Success
                                }
                            />
                        </li>
                    )}
                    {redemptionInformation?.vouchers.map(voucher => (
                        <li key={voucher.id} className={styles.voucherItem}>
                            <VoucherItem
                                {...voucher}
                                handleRemoveVoucherCode={handleRemoveVoucherCode}
                                setErrorMessage={setErrorMessage}
                            />
                            {redemptionInformation.vouchersCount > 1 && <hr />}
                        </li>
                    ))}
                </ul>

                <VoucherForm
                    inputValue={inputValue}
                    step={step}
                    error={error}
                    handleSubmit={handleSubmit}
                    handleInputChange={handleInputChange}
                    handleKeyDown={handleKeyDown}
                />
            </div>
        </div>
    );
};

export { VoucherModal };
export default VoucherModal; // eslint-disable-line import/no-default-export
